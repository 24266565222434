<template>
  <v-container>
    <v-card>
      <v-card-title>
        <v-icon large>
          {{ icon }}
        </v-icon>
        งาน SI
      </v-card-title>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          color="secondary"
          single-line
          hide-details
        />
      </v-card-actions>
      <v-card-text>
        <v-btn
          small
          color="secondary"
          class="ma-2"
          outlined
          @click="exportToExcel()"
        >
          <v-icon> mdi-microsoft-excel </v-icon>
          Export to Excel
        </v-btn>
        <v-data-table
          :headers="headers"
          :items="customerSIs"
          :search.sync="search"
          :options.sync="options"
          :footer-props="{ itemsPerPageOptions: [10, 50, 100] }"
          :loading="loading"
          loading-text="Loading..."
          :server-items-length="totalCustomerSIs"
          class="elevation-1"
        >
          <template v-slot:[`item.star`]="{ item }">
            <v-icon v-if="item.customerSI_income >= 30000 && item.customerSI_profitPerCostRatio >= 25" color="green">
              mdi-star
            </v-icon>
          </template>
          <template v-slot:[`item.customerSI_documentDate`]="{ item }">
            <span>
              {{ formatDate(item.customerSI_documentDate) }}
            </span>
          </template>
          <template v-slot:[`item.customerSI_requestBudget`]="{ item }">
            <span>
              {{
                new Intl.NumberFormat("th", {
                  style: "currency",
                  currency: "THB",
                }).format(item.customerSI_requestBudget)
              }}
            </span>
          </template>
          <template v-slot:[`item.customerSI_serviceFee`]="{ item }">
            <span>
              {{
                new Intl.NumberFormat("th", {
                  style: "currency",
                  currency: "THB",
                }).format(item.customerSI_serviceFee)
              }}
            </span>
          </template>
          <template v-slot:[`item.customerSI_paymentFormat`]="{ item }">
            <span>
              {{ paymentFormatEnum[item.customerSI_paymentFormat] }}
            </span>
          </template>
          <template v-slot:[`item.customerSI_annualIncome`]="{ item }">
            <span>
              {{
                new Intl.NumberFormat("th", {
                  style: "currency",
                  currency: "THB",
                }).format(item.customerSI_annualIncome)
              }}
            </span>
          </template>
          <template v-slot:[`item.customerSI_profit`]="{ item }">
            <span>
              {{
                new Intl.NumberFormat("th", {
                  style: "currency",
                  currency: "THB",
                }).format(item.customerSI_profit)
              }}
            </span>
          </template>
          <template v-slot:[`item.customerSI_cost`]="{ item }">
            <span>
              {{
                new Intl.NumberFormat("th", {
                  style: "currency",
                  currency: "THB",
                }).format(item.customerSI_cost)
              }}
            </span>
          </template>
          <template v-slot:[`item.customerSI_income`]="{ item }">
            <span>
              {{
                new Intl.NumberFormat("th", {
                  style: "currency",
                  currency: "THB",
                }).format(item.customerSI_income)
              }}
            </span>
          </template>
          <template v-slot:[`item.customerSI_projectStartDate`]="{ item }">
            <span>
              {{ formatDate(item.customerSI_projectStartDate) }}
            </span>
          </template>
          <template v-slot:[`item.customerSI_projectEndDate`]="{ item }">
            <span>
              {{ formatDate(item.customerSI_projectEndDate) }}
            </span>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-btn
              small
              color="secondary"
              class="ma-2"
              outlined
              :to="'/customer/' + item.customer_id + '/show'"
            >
              <v-icon> mdi-magnify </v-icon>
              Detail
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { mdiCogs } from "@mdi/js";

moment.locale("th");

export default {
  name: "CustomerSIList",
  props: {},
  data() {
    return {
      icon: mdiCogs,
      search: "",
      totalCustomerSIs: 0,
      options: {},
      customerSIs: [],
      loading: false,
      paymentFormatEnum: {
        MONTHLY: "รายเดือน",
        YEARLY: "รายปี",
        ONETIME: "ครั้งเดียว",
      },
      headers: [
        {
          text: "",
          value: "action",
          sortable: false,
          align: "center",
        },
        {
          text: "",
          value: "star",
          sortable: false,
          align: "center",
        },
        {
          text: "เลขที่หนังสือ",
          value: "customerSI_documentNumber",
          sortable: false,
          align: "center",
        },
        {
          text: "ลงวันที่",
          value: "customerSI_documentDate",
          sortable: false,
          align: "center",
        },
        {
          text: "บริการ",
          value: "customerSICategory_name",
          sortable: false,
          align: "center",
        },
        {
          text: "ลูกค้า",
          value: "customer_name",
          sortable: false,
          align: "center",
        },
        {
          text: "รายละเอียด",
          value: "customerSI_description",
          sortable: false,
          align: "center",
        },
        {
          text: "ส่วนบริการลูกค้า",
          value: "officeSector_name",
          sortable: false,
          align: "center",
        },
        {
          text: "งบประมาณที่ขอ",
          value: "customerSI_requestBudget",
          sortable: false,
          align: "center",
        },
        {
          text: "ค่าบริการ",
          value: "customerSI_serviceFee",
          sortable: false,
          align: "center",
        },
        {
          text: "รูปแบบการชำระเงิน",
          value: "customerSI_paymentFormat",
          sortable: false,
          align: "center",
        },
        {
          text: "จำนวนรอบบิล",
          value: "customerSI_billingCycles",
          sortable: false,
          align: "center",
        },
        {
          text: "รายได้ต่อปี",
          value: "customerSI_annualIncome",
          sortable: false,
          align: "center",
        },
        {
          text: "รายได้",
          value: "customerSI_income",
          sortable: false,
          align: "center",
        },
        {
          text: "ต้นทุน",
          value: "customerSI_cost",
          sortable: false,
          align: "center",
        },
        {
          text: "กำไร",
          value: "customerSI_profit",
          sortable: false,
          align: "center",
        },
        {
          text: "สัดส่วนกำไร (%)",
          value: "customerSI_profitPerCostRatio",
          sortable: false,
          align: "center",
        },
        {
          text: "สถานะ",
          value: "customerSIStatus_name",
          sortable: false,
          align: "center",
        },
        {
          text: "สถานะงบประมาณ",
          value: "customerSIBudgetStatus_name",
          sortable: false,
          align: "center",
        },
        {
          text: "ส่วนงานขาย",
          value: "customerSI_saleSector",
          sortable: false,
          align: "center",
        },
        {
          text: "พนักงานขาย",
          value: "customerSI_salePerson",
          sortable: false,
          align: "center",
        },
        {
          text: "วันเริ่มต้นโครงการ",
          value: "customerSI_projectStartDate",
          sortable: false,
          align: "center",
        },
        {
          text: "วันสิ้นสุดโครงการ",
          value: "customerSI_projectEndDate",
          sortable: false,
          align: "center",
        },
      ],
    };
  },
  watch: {
    options: {
      handler() {
        this.getDataFromAPI();
      },
      deep: true,
    },
    search: {
      handler() {
        this.getDataFromAPI();
      },
      deep: true,
    },
  },
  created() {},
  methods: {
    formatDate(date) {
      // return moment(date).format("LLL");
      return new Date(date).toLocaleDateString("th-TH", {
          dateStyle: "long",
        });
    },
    getDataFromAPI() {
      this.loading = true;
      const queryParams = {
        params: {
          search: this.search,
          page: this.options.page,
          per_page: this.options.itemsPerPage,
        },
      };
      axios
        .get("/customer-si", queryParams)
        .then((response) => {
          // JSON responses are automatically parsed.
          this.customerSIs = response.data.data;
          this.totalCustomerSIs = response.data.total;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    exportToExcel() {
      this.loading = true;
      const queryParams = {
        params: {
          search: this.search,
        },
        responseType: "arraybuffer",
      };
      axios
        .get("/export-to-excel/customer-si", queryParams)
        .then((response) => {
          // Extract filename from Content-Disposition header
          const contentDisposition = response.headers['content-disposition'];
          let filename = 'BCRM_ลูกค้า_SI.xlsx';
          if (contentDisposition) {
            const filenameMatch = contentDisposition.match(/filename\*=UTF-8''(.+)/);
            if (filenameMatch && filenameMatch.length === 2) {
              filename = decodeURIComponent(filenameMatch[1]);
            } else {
              const filenameMatch = contentDisposition.match(/filename="(.+)"/);
              if (filenameMatch && filenameMatch.length === 2) {
                filename = filenameMatch[1];
              }
            }
          }

          let blob = new Blob([response.data], {
            type: response.headers["content-type"],
          });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = filename;
          link.click();
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.scroll {
  overflow-y: scroll;
}
.v-data-table {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>